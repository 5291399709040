
import React, {useState, useEffect, useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {DataGrid} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import profileIcon from '..//../assets/User.png'


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'userGroup',
      headerName: 'Group Name',
      width: 250,
      editable: false,
    },
    {
      field: 'userRole',
      headerName: 'Role Name',
      width: 150,
      editable: false,
    },
    {
      field: 'Description',
      headerName: 'Description',
     // type: 'number',
      width: 410,
      editable: false,
    }
]

// const rows = [
//     { id: 1, userGroup: 'General', userRole: 'General', Description: 'This is a default user group and role for all emplyees'},
//     { id: 2, userGroup: 'Incident', userRole: 'New Report', Description: 'User can submit a new incident report'},
//     { id: 3, userGroup: 'Document', userRole: 'General', Description: ''},
//     { id: 4, userGroup: 'Hazard Asmt', userRole: 'Gernal', Description: ''},
//     { id: 5, userGroup: 'Emergency Response', userRole: 'Gernal', Description: ''},
//   ];

  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const MyProfile = ({user}) => {
    const [rows, setRows] = useState([]);
    
    useEffect(() => {

        let idx =0;
        let groups = [];

        for (let j=0; j<user.groups.length; j++){
            let roles = user.groups[j].roles;
            let name = user.groups[j].name;

            for (let i=0; i< roles.length; i++) {
                groups.push({"id": idx, "userGroup": name, "userRole": roles[i].roleName, "Description": roles[i].description} );
                idx ++;
            }
        };
        
        setRows(groups);
        
       console.log ("users profile:", rows);
    },[user]);

   


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
         <div style = {{width:"100%", height: "100%"}}>
                <Row>
                    <Col style = {{display: "flex", flexDirection: "column", alignItems:"center"}}>
                        <img src={profileIcon} alt="user profile picture" style={{width: '150px', height: '150px'}}/>
                        <p>{user.firstName + ' ' + user.lastName}</p>
                        <p>{user.email}</p>
                    </Col>
                </Row>
               
                <Row>
                    <Col style = {{display: "flex", flexDirection: "column" ,alignItems:"center" }}>
                        <Box sx={{ height: 510, padding: '0px',alignItems:"center" }}>
                            <Button variant="success" onClick={handleOpen}>Request New Role</Button>   
                            <Modal 
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="child-modal-title"
                                aria-describedby="child-modal-description"
                                >
                                    <Box sx={{ ...style}}>
                                        <Form>
                                            <Row>
                                                <Form.Group className="mb-3" controlId="userGroup">
                                                    <Form.Label>Group Name:</Form.Label>
                                                    <Form.Select area-label = "Default select example">
                                                        <option>Select a user group</option>
                                                        <option>Incident Report</option>
                                                        <option>Hazard Assessment</option>
                                                        <option>Document Management</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group className="mb-3" controlId="userRole">
                                                    <Form.Label>Role Name:</Form.Label>
                                                    <Form.Select area-label = "Default select example">
                                                        <option>Select a user role</option>
                                                        <option>Report New Incident</option>
                                                        <option>Investigator</option>
                                                        <option>Reviewer</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                        <Button variant="success" onClick={handleClose}>Submit</Button>  
                                    </Box>
                            </Modal>

                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 10,
                                    },
                                },
                                }}
                                pageSizeOptions={[5, 10, 15]}
                                checkboxSelection = {false}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </Col>
                </Row>
                
             </div>
            
           
            
        </>
    );
}

export default MyProfile;