import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Form, FormControl, Row, Col, Button, InputGroup } from "react-bootstrap";
// import DatePicker from 'react-datepicker';
import { FileUploader } from "react-drag-drop-files";
//import Table from "react-bootstrap/Table";
import NewIncidentReport from "./incident_reporting";
import {DataGrid} from '@mui/x-data-grid';
import './incident.css';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import incidentRecords from '../../sampleData/incidents.json'


function createData(id, reportNumber,  incidentDate, reportDate, category, location, status) {
    return {
      id,
      reportNumber,
      incidentDate,
      reportDate,
      category,
      location,
      status,
    };
  }


//   const rows = [
//   createData(1, 'INC000001', '2024-01-15', '2024-01-15', 'Lost Time', '35 20 St. NW Calgary', 'Completed'),
//   createData(2, 'INC000002', '2024-02-15', '2024-02-15', 'Lost Time', '35 20 St. NW Calgary', 'Completed'),
//   createData(3, 'INC000003', '2024-02-25', '2024-02-25', 'Body Injury', '35 20 St. NW Calgary', 'Investigation'),
//   createData(4, 'INC000004', '2024-03-15', '2024-03-15', 'Lost Time', '35 20 St. NW Calgary', 'Investigation'),
//   createData(5, 'INC000005', '2024-03-15', '2024-03-15', 'Body Injury', '35 20 St. NW Calgary', 'Investigation'),
//   createData(6, 'INC000006', '2024-03-25', '2024-03-25', 'Body Injury', '35 20 St. NW Calgary', 'Investigation'),
//   createData(7, 'INC000007', '2024-04-05', '2024-04-05', 'Lost Time', '35 20 St. NW Calgary', 'Investigation'),
//   createData(8, 'INC000008', '2024-04-15', '2024-05-15', 'Body Injury', '35 20 St. NW Calgary', 'Not Submitted'),
// ];

  let rows = incidentRecords.incidents.map ((incident) => (
       createData(incident.id, incident.incidentNum, incident.incidentDate, incident.incidentRptDate,incident.category,incident.location, incident.status)
  ));

  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'reportNumber',
      numeric: false,
      disablePadding: true,
      label: 'Report Number',
    },
    {
      id: 'incidentDate',
      numeric: false,
      disablePadding: false,
      label: 'Incident Date',
    },
    {
      id: 'reportDate',
      numeric: false,
      disablePadding: false,
      label: 'Report Date',
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: 'Category',
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'right'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {/* {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            My Incident Reports
          </Typography>
        )} */}
         <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            My Incident Reports
          </Typography>

        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )} */}
        <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

const IncidentReportSummary = ({onSelectRecord, currentRole, employeeId}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('reportNumber');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [incidentRec, setincidentRec] = useState([]);

   //get user roles
  //  const [isGeneralRole, setIsGenergalRole] = useState(false);
  //  const [isInvestigationRole, setIsInvestigationRole] = useState(false);
  //  const [isReviewRole, setIsReviewRole] = useState(false);
 
  //  useEffect(() => {
  //      for (let j=0; j<user.groups.length; j++){
  //          let roles = user.groups[j].roles;
  //          let name = user.groups[j].name;

  //          if (name === 'incident') {
  //              for (let i=0; i< roles.length; i++) {
  //                  if (roles[i].roleName === 'review') {
  //                      setIsReviewRole(true);
  //                  }
  //                  else if (roles[i].roleName === 'investigation') {
  //                      setIsInvestigationRole(true);
  //                  }
  //                  else {
  //                      setIsGenergalRole(true);
  //                  }
  //              }
  //          }
  //      };
       
  //      //filter incidents by login user employee ID
  //       let records = incidentRecords.incidents.filter ((row) => row.reportedBy.employeeID === user.employeeID);
      
  //       //filter incidents by login user role
  //       if (isInvestigationRole) {
  //         records = incidentRecords.incidents.filter ((row) => row.status === 'investigation' || row.status === 'investigation completed');
  //       }

  //       if (isReviewRole) {
  //         records = incidentRecords.incidents.filter ((row) => row.status === 'investigation completed' || row.status === 'review');
  //       }

  //       setincidentRec(records);
        
  //     // console.log ("trigger me!", incidentRec);
  //   },[]);

    // const rows = incidentRec.map ((incident) => (
    //   createData(incident.id, incident.incidentNum, incident.incidentDate, incident.incidentRptDate,incident.category,incident.location, incident.status)
    //  ));

     //console.log ("rows records:", rows);
  //filter incidents by login user employee ID
     if (currentRole === 'general') {
        let records = incidentRecords.incidents.filter ((row) => row.reportedBy.employeeID === employeeId);
     }
        //filter incidents by login user role
        if (currentRole === 'investigation') {
          rows = rows.filter ((row) => row.status === 'investigation' || row.status === 'investigation completed');
        }

        if (currentRole === 'review') {
          rows = rows.filter ((row) => row.status === 'closed' || row.status === 'review');
        }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // const handleDoubleClick = (event, id) => {
  //   console.log ("id:", id);
  //   console.log ("event", event);
  //   console.log ("data", rows[id-1]);

    
  //   getIncidentRecord(rows[id-1]);
  // };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    onDoubleClick={(event) => onSelectRecord( incidentRecords.incidents[row.id-1])}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="right"
                    >
                      {row.reportNumber}
                    </TableCell>
                    {/* <TableCell align="right">{row.reportNumber}</TableCell> */}
                    <TableCell align="right">{row.incidentDate}</TableCell>
                    <TableCell align="right">{row.reportDate}</TableCell>
                    <TableCell align="right">{row.category}</TableCell>
                    <TableCell align="right">{row.location}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
    
    // return (
    //     <>
    //     <div style = {{width: "100%",  padding: "20px"}}>
    //         <div>
    //             <Row>
    //                 <Col>
    //                      <h3> My Incident Reports </h3>
    //                 </Col>
    //                 <Col style = {{display: "d-flex", justifyContent:"right"}}>
    //                     <Form className="d-flex">
    //                         <InputGroup>
    //                             <FormControl type="search" className="me-2" placeholder="Search" />
    //                             <InputGroup.Text className="bg-white">
    //                                 Search
    //                                 {/* <svg
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                                 viewBox="0 0 24 24"
    //                                 width="16"
    //                                 height="16"
    //                                 fill="currentColor"
    //                                 >
    //                                 <path
    //                                     fill-rule="evenodd"
    //                                     d="M9.5 3a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM1 9.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0z"
    //                                 />
    //                                 <path
    //                                     fill-rule="evenodd"
    //                                     d="M16.853 16.854a.5.5 0 0 0 .707 0l3.793-3.793a.5.5 0 0 0 0-.707l-3.793-3.793a.5.5 0 0 0-.707.707L19.293 12H10.5a.5.5 0 0 0 0 1h8.793l-2.646 2.646a.5.5 0 0 0 0 .707z"
    //                                 />
    //                                 </svg> */}
    //                             </InputGroup.Text>
                           
    //                         </InputGroup>
                
    //                     </Form>
    //                 </Col>
    //             </Row>
                
    //         </div>
            
    //         <hr/>
    //         {/* <Table responsive bordered hover>
    //             <thead>
    //                 <tr> 
    //                     <th></th>
    //                     <th>#</th>
    //                     <th>Report Number</th>
    //                     <th>Report Date</th>
    //                     <th>Incident Date</th>
    //                     <th>Incident Category</th>
    //                     <th>Incident Location</th>
    //                     <th>Status</th>
                       
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 <tr>
    //                     <td>
    //                         <Button size="sm" variant="success" onClick={OpenIncidentReport}>Open</Button>
    //                     </td>
    //                     <td>1</td>
    //                     <td>INC000001</td>
    //                     <td>2024-03-20</td>
    //                     <td>2024-03-20</td>
    //                     <td>Lost Time</td>
    //                     <td>300 10 St NW, Calgary</td>
    //                     <td>Not Submitted</td>
    //                 </tr>
    //                 <tr>
    //                     <td>
    //                         <Button size="sm" variant="success">Open</Button>
    //                     </td>
    //                     <td>2</td>
    //                     <td>INC000002</td>
    //                     <td>2024-03-20</td>
    //                     <td>2024-03-20</td>
    //                     <td>Lost Time</td>
    //                     <td>300 10 St NW, Calgary</td>
    //                     <td>Investigation</td>

    //                 </tr>
    //                 <tr>
    //                     <td>
    //                         <Button size="sm" variant="success">Open</Button>
    //                     </td>
    //                     <td>3</td>
    //                     <td>INC000003</td>
    //                     <td>2024-03-20</td>
    //                     <td>2024-03-20</td>
    //                     <td>Lost Time</td>
    //                     <td>300 10 St NW, Calgary</td>
    //                     <td>Closed</td>
                     
    //                 </tr>
    //             </tbody>
          
    //         </Table> */}
    //     </div>
    //     </>
    // );
}

export default IncidentReportSummary;