import logo from './assets/SGALogo.png';
import docMgmtIcon from './assets/DocMgmt.png';
import incidentReportIcon from './assets/IncidentReport.png';
import employeeIcon from './assets/User.png';
import hazardIcon from './assets/HazardAsmt.png';
import meetingIcon from './assets/meetings.png';
import inspectionIcon from './assets/Inspections.png';
import ergonomicsIcon from './assets/ergonomics.png';
import fleetIcon from './assets/fleet.png';
import competenceIcon from './assets/Competence.png';
import analyticsIcon from './assets/analytics.png';
import emergencyIcon from './assets/emergency.png';
import './App.css';
import React, {useState, useEffect, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup  from 'react-bootstrap/ButtonGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Row, Col , Button} from 'react-bootstrap';
import {Tabs, Tab, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Login from "./components/login/login";
import IncidentReportSummary from "./components/incident/incident_reports";
import NewIncidentReport from './components/incident/incident_reporting';
import InvestigateIncident from './components/incident/incident_investigate';
import ReviewIncident from './components/incident/incident_review';
import MyProfile from './components/profile/myProfile';
import MyDashboard from './components/analytics/myDashboard';

import users from './sampleData/users.json';
import { SettingsInputSvideoTwoTone } from '@mui/icons-material';

const userList = users.users.map ((user) => {
  return (user)
});


function App() {
 
  //let currentUser = userList[1];
  
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState('general');
  const [userGroups, setUserGroups] = useState([]);
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [activeKey, setActiveKey] = useState('0');

   //menu items
  const [isDocumentMgmt, setIsDocumentMgmt] = useState(false);
  const [isIncidnetMgmt, setIsIncidentMgmt] = useState(false);
  const [isHazardMgmt, setIsHazardMgmt] = useState(false);
  const [isEmergencyMgmt, setIsEmergencyMgmt] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [isSafetyMeeting, setIsSafetyMeeting] = useState(false);
  const [isVehicleMgmt, setIsVehicleMgmt] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [isInsepection, setIsInspection] = useState(false);
  const [isErgonomics, setIsErgonomics] = useState(false);
  const [isCompetence, setIsCompetence] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [leftPanel, setLeftPanel] = useState (null);
  const [rightPanel, setRightPanel] = useState (null);
  const [leftPanelWidth, setLeftPanelWidth] = useState(0);
  const [rightPanelWidth, setRightPanelWidth] = useState(0);
  const [splitterX, setSplitterX] = useState(0);

  const [topPanel, setTopPanel] = useState (null);
  const [bottomPanel, setBottomPanel] = useState (null);
  const [topPanelHeight, setTopPanelHeight] = useState(0);
  const [bottomPanelHeight, setBottomPanelHeight] = useState(0);
  const [splitterY, setSplitterY] = useState(0);

  const [direction, setDirection] = useState(null);

  const [userProfile, setUserProfile] = useState (true);
  const [userDashboard, setUserDashboard] = useState (false);
  const [newIncidentReport, setNewIncidentReport] = useState (false);
  const [incidentSumReport, setIncidentSumReport] = useState (false);
  const [investigateReport, setInvestigateReport] = useState (false);
  const [incidentSelected, setIncidentSelected] = useState(null);

  //console.log ("usrs: ", currentUser);


  const onMouseDown = ((e) => {
    
    setIsDragging(true);
    setDirection("column");
    setLeftPanel(e.target.previousElementSibling);
    setRightPanel(e.target.nextElementSibling);

    setSplitterX(e.pageX);
    setLeftPanelWidth(e.target.previousElementSibling.offsetWidth);
    setRightPanelWidth(e.target.nextElementSibling.offsetWidth);

  });

  const onMouseDownRow = ((e) => {
    setIsDragging(true);
    setDirection("row");

    setTopPanel(e.target.previousElementSibling);
    setBottomPanel(e.target.nextElementSibling);
    setSplitterY(e.pageY);

    setTopPanelHeight(e.target.previousElementSibling.offsetHeight);
    setBottomPanelHeight(e.target.nextElementSibling.offsetHeight);

  });

  const onMouseMove = ((e) => {

    if(direction === "column") {

      let movex = e.pageX - splitterX;
      
      leftPanel.style.width = (leftPanelWidth + movex) + "px";
      rightPanel.style.width = (rightPanelWidth - movex) + 'px';
    }
    else {
      let movey = e.pageY - splitterY;
      
      topPanel.style.height = (topPanelHeight + movey) + "px";
      bottomPanel.style.height = (bottomPanelHeight - movey) + 'px';
    }

    

  });

  const onMouseUp = ((e) => {
    setIsDragging(false);
    setDirection(null);

    setLeftPanel(null);
    setRightPanel(null);
    setSplitterX(null);

    setTopPanel(null);
    setBottomPanel(null);
    setSplitterY(null);

  });

 
  const Appbody = () => (
        <>
         <div className='left-panel'>
          <Accordion id = "mainMenu" activeKey={activeKey} onSelect = {(e) => setActiveKey(e)}>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <Image className= 'imgbox' src={employeeIcon}  rounded />
                &nbsp;Employee
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='' onClick = {handleUserProfile} >
                    <a className="nav-link active" href="#">&nbsp;&nbsp;My Profile </a>
                  </li>
                  {/* <li className='' onClick = {handleUserDashboard} >
                    <a className="nav-link active" href="#">&nbsp;&nbsp;My Dashboard </a>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {isDocumentMgmt && <Accordion.Item eventKey='1'>
                              <Accordion.Header>
                                <Image className = 'imgbox' src={docMgmtIcon} rounded />
                                    &nbsp;Documents Management
                              </Accordion.Header>
                              <Accordion.Body>
                                this is for employee
                              </Accordion.Body>
                            </Accordion.Item> }
            {isIncidnetMgmt && <Accordion.Item eventKey='2'>
                            <Accordion.Header>
                              <Image className = 'imgbox' src={incidentReportIcon} rounded />
                                    &nbsp;Incident Reporting
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul className='nav flex-column'>
                                <li className='nav-item' onClick={navIncident('new')}>
                                  <a className="nav-link active" href="#">&nbsp;&nbsp;New Incident Report</a>
                                </li>
                                <li className='nav-item' onClick={navIncident('sum')}>
                                  <a className="nav-link active" href="#">&nbsp;&nbsp;My Incident Reports</a>
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item> }
            {isHazardMgmt && <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <Image className = 'imgbox' src={hazardIcon} rounded />
                    &nbsp;Hazard/Risk Assessment
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className='nav flex-column'>
                      <li className='nav-item'>
                        <a className="nav-link active" href="#">&nbsp;&nbsp;New Hazard/Risk Assessment</a>
                      </li>
                      <li className='nav-item'>
                        <a className="nav-link active" href="#">&nbsp;&nbsp;Hazard/Risk Assessments</a>
                      </li>
                      <li className='nav-item'>
                        <a className="nav-link active" href="#">&nbsp;&nbsp;Analytics Dashboards</a>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item> }
            {isSafetyMeeting && <Accordion.Item eventKey='4'>
              <Accordion.Header>
                <Image className = 'imgbox' src={meetingIcon} rounded />
                    &nbsp;Safety Meetings
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;New Meeting Minutes</a>
                  </li>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Meeting Mintues</a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>}
            {isEmergencyMgmt && <Accordion.Item eventKey='5'>
                    <Accordion.Header>
                    <Image className = 'imgbox' src={emergencyIcon} rounded />
                          &nbsp;Emergency Response Drills
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className='nav flex-column'>
                        <li className='nav-item'>
                          <a className="nav-link active" href="#">&nbsp;&nbsp;Emergency Response Docs</a>
                        </li>
                        <li className='nav-item'>
                          <a className="nav-link active" href="#">&nbsp;&nbsp;Emergency Response Reports</a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item> }
            {isInsepection && <Accordion.Item eventKey='6'>
              <Accordion.Header>
              <Image className = 'imgbox' src={inspectionIcon} rounded />
                    &nbsp;Inspections & Observations
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;New Inspection Report</a>
                  </li>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Inspection Reports</a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
            {isErgonomics && <Accordion.Item eventKey='7'>
              <Accordion.Header>
              <Image className = 'imgbox' src={ergonomicsIcon} rounded />
                    &nbsp;Ergonomics Assessment
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Ergonomics Docs</a>
                  </li>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Ergonomics Asessment Reports</a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
            { isCompetence && <Accordion.Item eventKey='8'>
              <Accordion.Header>
              <Image className = 'imgbox' src={competenceIcon} rounded />
                    &nbsp;Competence Assessment
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Employee Assessment</a>
                  </li>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Employee Assessments</a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
            {isVehicleMgmt && <Accordion.Item eventKey='9'>
              <Accordion.Header>
                 <Image className = 'imgbox' src={fleetIcon} rounded />
                    &nbsp;Fleet Management
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Vehicle Information</a>
                  </li>
                  {/* <li className='nav-item'>
                    <a className="nav-link active" href="#">New Incident Report</a>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
            {isAnalytics && <Accordion.Item eventKey='10'>
              <Accordion.Header>
                <Image className = 'imgbox' src={analyticsIcon} rounded />
                    &nbsp;Analytics Tools
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Reports</a>
                  </li>
                  <li className='' onClick = {handleUserDashboard} >
                    <a className="nav-link active" href="#">&nbsp;&nbsp;Dashboards </a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
            {isSystemAdmin && <Accordion.Item eventKey='11'>
              <Accordion.Header>
                <Image className = 'imgbox' src={employeeIcon} rounded />
                    &nbsp;System Adminsitraction
              </Accordion.Header>
              <Accordion.Body>
                <ul className='nav flex-column'>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;User Management</a>
                  </li>
                  <li className='nav-item'>
                    <a className="nav-link active" href="#">&nbsp;&nbsp;System Lookups</a>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> }
          </Accordion>

        </div>
        <div className='splitter' onMouseDown={onMouseDown}></div>
        <div className='right-panel'>
         
          <div style={{height: "89%"}} >
             {userProfile && <MyProfile user = {currentUser}/> } 
             {userDashboard && <MyDashboard/> }
             {newIncidentReport && <NewIncidentReport user = {currentUser}/>}   
             {incidentSumReport && <IncidentReportSummary onSelectRecord = {getIncidentRecord}  currentRole = {currentUserRole} employeeId = {currentUser.employeeID}/>} 
             {investigateReport && <InvestigateIncident rec = {incidentSelected}  user = {currentUser} currentRole = {currentUserRole}/>}
          </div>
        </div>
      </>
    
  );
  

  useEffect (() => {
    if (isDragging) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  
  }, [isDragging])

  const updateNavItem =  (item, val) => {

    setUserProfile(false);
    setUserDashboard(false);
    setNewIncidentReport(false);
    setIncidentSumReport(false);
    setInvestigateReport(false);


    switch (item) {
      case 'setUserProfile':
        setUserProfile(val);
        break;
      case 'setUserDashboard':
        setUserDashboard(val);
        break;
      case 'setNewIncidentReport':
        setNewIncidentReport(val);
        break;
      case 'setIncidentSumReport':
        setIncidentSumReport(val);
        break;
      case 'setInvestigateReport':
        setInvestigateReport(val);
        break;
      default:
        break;
    }

    

  };

  //new incident report
  const navIncident =(chg) => ()=> {
    //console.log ("ref:", rightPanelRef1.current);
    setInvestigateReport(false);

    if (chg === 'new') {
      updateNavItem ('setNewIncidentReport', true);
    }
    else {
      // setIncidentSumReport(true);
      // setNewIncidentReport(false);
      updateNavItem ('setIncidentSumReport', true);
    }
  }

  const getIncidentRecord = (rec) => {
    //console.log ('incident:', rec);
   
    // setNewIncidentReport(false);
    // setIncidentSumReport(false);

    setIncidentSelected(rec);

    //setInvestigateReport(true);
    updateNavItem ('setInvestigateReport', true);

  }

  const getLoginUser = (cuser) => {
    setIsUserLogin (true);

    setCurrentUser(cuser);
    // console.log ("user loged in :", cuser);
   
    let ugroups = [];

    for (let j=0; j<cuser.groups.length; j++){
           let roles = cuser.groups[j].roles;
           let name = cuser.groups[j].name;

           ugroups.push (name);

           if (name === 'incident') {
               for (let i=0; i< roles.length; i++) {
                   if (roles[i].roleName === 'review') {
                       //setIsReviewRole(true);
                       setCurrentUserRole('review')
                   }
                   else if (roles[i].roleName === 'investigation') {
                      // setIsInvestigationRole(true);
                      setCurrentUserRole('investigation')
                   }
                   else {
                       //setIsGenergalRole(true);
                       setCurrentUserRole('general')
                   }
               }
           }
       };

       setUserGroups(ugroups);
       //console.log ("user groups", userGroups);
       //enableMenuItem();

  }

  //const enableMenuItem = () => {
  useEffect(() => {
    for (let i=0; i< userGroups.length; i++) {
      switch (userGroups[i]) {
        case 'document':
          setIsDocumentMgmt(true);
          break;
        case 'incident':
          setIsIncidentMgmt(true);
          break;
        case 'hazard':
            setIsHazardMgmt(true);
            break;
        case 'emergency':
            setIsEmergencyMgmt(true);
            break;
        case 'inspection':
          setIsInspection(true);
          break;
        case 'safety meetings':
            setIsSafetyMeeting(true);
            break;
        case 'ergonomics':
            setIsErgonomics(true);
            break;
        case 'analytics':
            setIsAnalytics(true);
            break;
        case 'competence' :
            setIsCompetence(true);
            break;
        case 'fleet':
            setIsVehicleMgmt(true);
            break;
        case 'system':
            setIsSystemAdmin(true);
            break;
        default:
          break;
      }

    }
  //};
}, [userGroups])


  


  const handleUserProfile = () => {
    //setUserProfile(true);
    updateNavItem ('setUserProfile', true);
  };

  const handleUserDashboard = () => {
    updateNavItem ('setUserDashboard', true);
  };

  const handleLogout = () => {
    setCurrentUser(null);

    setIsDocumentMgmt(false);
    setIsIncidentMgmt(false);
    setIsHazardMgmt(false);
    setIsEmergencyMgmt(false);
    setIsInspection(false);
    setIsErgonomics(false);
    setIsAnalytics(false);
    setIsCompetence(false);
    setIsVehicleMgmt(false);
    setIsSystemAdmin(false);
    setIsSafetyMeeting(false);

    setActiveKey('0');

    // const leftPanel = document.getElementsByClassName('left-panel');
    // leftPanel.remove();

    // const splitter = document.getElementsByClassName('splitter').remove();
    // splitter.remove();

    // const rightPanel = document.getElementsByClassName('right-panel').remove();
    // rightPanel.remove();

    setIsUserLogin(false);
  }

  return (
    <>
        
    <div className='app-header'>
      <Row>
          <Col  xs="auto">
            <img src= {logo}  width="50" height = "32"  alt="AFSC" />
          </Col>
          <Col xs="auto">
            <span>Safety and Health Management System</span>
          </Col>
          <Col></Col>
         { isUserLogin && <Col xs="auto"> Welcome: {currentUser.firstName + ' ' + currentUser.lastName} </Col> }
         { isUserLogin && <Col xs="auto"> 
             <Button variant='success' onClick={handleLogout}>Log Out</Button>
            </Col>
          }
      </Row>
    </div>
    <div className="App">
      <>
        { !isUserLogin && <Login loginuser = {getLoginUser}/> } 
        { isUserLogin && <Appbody /> }
      </>
       
    </div>
    <div className='app-footer'>

    </div>
    </>
  );
}

export default App;
