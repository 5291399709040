import React, {useState} from "react";
import "../login/login.css";
import users from '../../sampleData/users.json';
import googleIcon from '../../assets/gmail.png';
import twitterIcon from '..//../assets/twitter.png';
import linkedIcon from '../../assets/linkedin.png';

const userList = users.users.map ((user) => {
  return (user)
});

const errors = {
    uname: "invalid username",
    password: "invalid password"
  };

const Login = ({loginuser})=> {

    const [userID, setUserID] = useState(null);
    const [userPwd, setUserPwd] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});

    const handleNameChange = (e) => {
        console.log("login user id: ", e.target.value);
        setErrorMessages({name: "null", message: 'null'});
        setUserID(e.target.value);
        
    }

    const handlePasswordChange = (e) => {
        setUserPwd(e.target.value);
    }

    const validateUser = (e) => {
       console.log ("validate user:", userID);

       e.preventDefault();

       console.log ("list user:", userList);
       const findUser = userList.find (user => user.userid == userID);
       console.log ("find user:", findUser);
              
       if (typeof findUser != "undefined"){
            if (userPwd === "1234") {
                setIsValid(true);
                loginuser(findUser);
            }
            else {
                setIsValid(false);
                setErrorMessages({name: "password", message: errors.password})
            }
       }
       else {
            setIsValid(false);
            setErrorMessages({name: "uname", message: errors.uname})
       }
    }
    
    
    const renderErrorMsg = (err) => err === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
    )

    return (
        <>
            <div id ="loginform">
                <h2 id="headerTitle">Login</h2>
                <div className="loginform-row">
                    <label>User Name</label>
                    <input type="text" required placeholder="Enter your username" id="uname" onChange = {handleNameChange}/>
                    {renderErrorMsg("uname")}
                </div>  
                <div className="loginform-row">
                    <label>Password</label>
                    <input type="password" required placeholder="Enter your password" id="upwd" onChange = {handlePasswordChange}/>
                    {renderErrorMsg("password")}
                </div>  
                <div id="loginform-button" className="loginform-row">
                    <button onClick={validateUser}>Log in</button>
                </div>
                <div id="alternativeLogin">
                    <label>Or sign in with:</label>
                    <div id="iconGroup">
                        <a href="#" >
                            <img src={googleIcon} style = {{width: '3rem', height: '3rem'}}/>
                        </a>
                        <a href="#">
                            <img src={linkedIcon} style = {{width: '3rem', height: '3rem'}}/>
                        </a>
                        <a href="#">
                            <img src={twitterIcon} style = {{width: '3rem', height: '3rem'}}/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Login;