import React, {useState,useEffect, useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
// import DatePicker from 'react-datepicker';
import { FileUploader } from "react-drag-drop-files";
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import './incident.css';


const NewIncidentReport = ({user}) => {

    const mapDiv = useRef();
    const [mapView, setMapView] = useState (null);
    const [map, setMap] = useState (null);
    
    const [hasWitness, setHasWitness] = useState('no');
    const [hasInjury, setHasInjury] = useState('no');
    const [hasEvidence, setHasEvidence] = useState('no');
    const [saved, setSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);

    const incidentHasEvidence = (e) =>{
          
        e.target.value ==='yes' ? setHasEvidence('yes') : setHasEvidence('no');
    }
    
    const incidentHasInjury =  (e) => {
        e.target.value === 'yes' ? setHasInjury('yes') : setHasInjury('no');
    }

    const incidentHasWitness =  (e) => {
        console.log ("output:", e.target.value );

        e.target.value === 'yes' ? setHasWitness('yes') : setHasWitness('no');
    }

    const SaveReport = (e) => {
        setSaved(true);

        setDisableSaveButton(true);
        setDisableSubmitButton(false);
    }

    const SubmitReport = (e) => {
        setSubmitted(true);

        setDisableSaveButton(true);
        setDisableSubmitButton(true);

    }

    useEffect(()=> {
         const map = new Map ({
         basemap: "satellite"

       });

       const mapView = new MapView({
       container: mapDiv.current,
         map: map,
         center: [-113.744, 52.477],
         zoom: 16,
         constraints: {
           maxZoom: 17,
           minZoom: 8,
         },
       
       });

       const searchWidget = new Search ({
        view: mapView,
        allPlaceholder: "",
        includeDefaultSources: true,
        sources: [
        //   {
        //     layer: fLayer,
        //     searchFields: ["NAME","STATE","COUNTY"],
        //     displayField: "NAME",
        //     exactMatch: false,
        //     outFields: ["*"],
        //     name: "POPULATION",
        //     placeholder: "e.g., Arizona"
        //   },
        //   {
        //     name: "ArcGIS World Geocoding Service",
        //     placeholder: "example: 61024 Dwight McLellan Trail",
        //     apiKey: "YOUR_API_KEY",
        //     singleLineFieldName: "SingleLine",
        //     url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer"
        //   }
        ]
      });
      mapView.ui.add(searchWidget, "top-right");

      

       mapView.when (() =>{
        setMapView (mapView);
        setMap (map);
     });
    }, []);

    return (
        <>
        <div style = {{width: "100%",  padding: "20px"}}>
            <div>
                <Row>
                    <Col>
                         <h3> Incident Report </h3>
                    </Col>
                    <Col style = {{display: "flex", justifyContent:"right"}}>
                        <label>Incident Number: INC000001</label>
                    </Col>
                </Row>
                
            </div>
            
            <hr/>
            <Form style={{width: "100%", height:"650px", overflowY:"auto", overflowX:"hidden"}}>
                <Row className="mb-3">
                    <Form.Group as = {Col} className = "mb-3"  controlId="employeeId">
                        <Form.Label>Employee ID:</Form.Label>
                        <Form.Control type="text" required pleaceholder = "Enter Employee ID" value = {user.employeeID} disabled={true}/>
                    </Form.Group>
                    <Form.Group as = {Col} className = "mb-3"  controlId="userFirstName">
                        <Form.Label>First Name:</Form.Label>
                        <Form.Control type="text" required pleaceholder = "Enter First Name" value = {user.firstName}  disabled={true}/>
                    </Form.Group>
                    <Form.Group as = {Col} className = "mb-3" controlId="userLastName"  >
                        <Form.Label>Last Name:</Form.Label>
                        <Form.Control type="text" pleaceholder = "Enter Last Name" value = {user.lastName}  disabled={true}/>
                    </Form.Group>
                    <Form.Group as = {Col} className = "mb-3" controlId="userEmail">
                        <Form.Label>Email Address:</Form.Label>
                        <Form.Control type="email" pleaceholder = "Enter email" value = {user.email}  disabled={true}/>
                    </Form.Group>
                    <Form.Group as = {Col} className = "mb-3" controlId="userPhone">
                        <Form.Label>Phone #:</Form.Label>
                        <Form.Control type="text" pleaceholder = "(403) 000-0000" value = {user.phone}  disabled={true}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Col >
                        <div id="mapDiv" style = {{width: "100%", height: "350px"}}  ref = {mapDiv}> 
                            <>
                            
                            </>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Form.Group as = {Col} className="mb-3" controlId="address">
                                <Form.Label>Incident Location:</Form.Label>
                                <Form.Control type = "text" placeholder="Enter the incident location" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as = {Col} className="mb-3" controlId="coordinates">
                                <Form.Label>Incident Location Coordinates:</Form.Label>
                                <Form.Control type = "text" placeholder="Enter the incident location" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as = {Col} className="mb-3" controlId="occurredDate">
                                <Form.Label>Incident Occurred Date:</Form.Label>
                                <Form.Control type = "date" placeholder="Enter the incident Date" />
                                
                            </Form.Group>
                            <Form.Group as = {Col} className="mb-3" controlId="occurredTime">
                                <Form.Label>Incident Occurred Time:</Form.Label>
                                <Form.Control type = "time" placeholder="Enter the incident location" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as = {Col} className="mb-3" controlId="reportedDate">
                                <Form.Label>Incident Reported Date:</Form.Label>
                                <Form.Control type = "date" placeholder="Enter the incident Date" />
                                
                            </Form.Group>
                            <Form.Group as = {Col} className="mb-3" controlId="reportedTime">
                                <Form.Label>Incident Occurred Time:</Form.Label>
                                <Form.Control type = "time" placeholder="Enter the incident location" />
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="incidentCategory">
                        <Form.Label>Incident Category:</Form.Label>
                        <Form.Select area-label = "Default select example">
                            <option>Select a incident type</option>
                            <option>Lost Time</option>
                            <option>Vehicle Collision</option>
                            <option>Body Injury</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlID='incidentDescription'>
                        <Form.Label>Incident Description:</Form.Label>
                        <Form.Control as="textarea" spellCheck={true} rows={4} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                        <Form.Label column sm={3}>Were there any witnesses to the incident?</Form.Label>
                        <Col sm={1}>
                            <Form.Check required type="radio" label={"Yes"} name= "witnessGroup" id="incidentWitnessYes" value = "yes" checked = {hasWitness === 'yes'} onChange={incidentHasWitness}/> 
                        </Col>
                        <Col sm={1}>
                            <Form.Check required type="radio" label={"No"}  name= "witnessGroup" id="incidentWitnessNo"  value = "no" checked = {hasWitness === 'no'} onChange={incidentHasWitness}/>
                        </Col>
                    </Form.Group>
                    {hasWitness === 'yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                        <Form.Label>Witness Contact Info:</Form.Label>
                        <Form.Control as="textarea" spellCheck={true} rows={2} />
                    </Form.Group> }
                </Row>
                <Row>
                    <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                        <Form.Label column sm = {2} >Were there any injuries?</Form.Label>
                       
                        <Col sm={1}>
                            <Form.Check required type="radio" label={"Yes"} name= "witnessInjuryGroup" id="incidentInjuryYes" value = "yes" checked = {hasInjury === 'yes'} onChange={incidentHasInjury}/> 
                        </Col>
                        <Col sm={1}>
                            <Form.Check required type="radio" label={"No"}  name= "witnessInjuryGroup" id="incidentInjuryNo" value = "no" checked = {hasInjury === 'no'} onChange={incidentHasInjury}/>
                        </Col>
                    </Form.Group>
                    {hasInjury==='yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                        <Form.Label>Injury Description:</Form.Label>
                        <Form.Control as="textarea" spellCheck={true} rows={2} />
                    </Form.Group> }
                </Row>
                <Row>
                    <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                       
                        <Form.Label column sm={4}> Do you have any evidence(photos, videos, etc.) of the incident?</Form.Label>
                        
                        <Col sm={1}>
                            <Form.Check required type="radio" label={"Yes"} name= "witnessEvidenceGroup" id="incidentEvidenceYes" value = "yes" checked = {hasEvidence === 'yes'} onChange={  incidentHasEvidence}/> 
                        </Col>
                        <Col sm = {1}>
                            <Form.Check required type="radio" label={"No"}  name= "witnessEvidenceGroup" id="incidentEvidenceNo" value = "no" checked = {hasEvidence === 'no'} onChange={incidentHasEvidence} />
                        </Col>
                    </Form.Group>
                    { hasEvidence==='yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                        <Form.Label>Please upload the evidence here:</Form.Label>
                        <FileUploader name="incidentFiles" types={["JPG",'png',"GIF"]}/>
                    </Form.Group> }
                </Row>
                <Row>
                     <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                        <Form.Label>The following employee(s) will be notified:</Form.Label>
                        <Form.Control as="textarea" spellCheck={true} rows={1} />
                    </Form.Group> 
                </Row>
            </Form>
            <Row>
                <Col sm = {1}>
                    <Button  variant = "primary" type="save" disabled={disableSaveButton} onClick={SaveReport}>
                        Save Report
                    </Button>
                </Col>
                <Col sm = {1}>
                    <Button  variant = "primary" type="submit" disabled={disableSubmitButton} onClick={SubmitReport}>
                        Submit Report
                    </Button> 
                </Col>
            </Row>
            
        </div>
        </>
    );
}

export default NewIncidentReport;