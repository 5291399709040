import React, {useState, useEffect, useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
// import DatePicker from 'react-datepicker';
import { FileUploader } from "react-drag-drop-files";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import './incident.css';


const InvestigateIncident = ({rec, user, currentRole}) => {

    const mapDiv = useRef();
    const [mapView, setMapView] = useState (null);
    const [map, setMap] = useState (null);

    const [value, setValue] = useState('1');

    const [hasWitness, setHasWitness] = useState('no');
    const [hasInjury, setHasInjury] = useState('no');
    const [hasEvidence, setHasEvidence] = useState('no');
    const [saved, setSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [investigateTabDisabled, setInvestigateTabDisabled] = useState(false);
    const [reviewTabDisabled, setReviewTabDisabled] = useState(false);
    const [readElem, setReadElem] = useState(false);
    const [readContact, setReadContact] = useState(false);
    const [readElemInvestigate, setReadElemInvestigate] = useState(false);
    const [readElemReview, setReadElemReview] = useState(false);

    //get user roles
    // const [isGeneralRole, setIsGenergalRole] = useState(false);
    // const [isInvestigationRole, setIsInvestigationRole] = useState(false);
    // const [isReviewRole, setIsReviewRole] = useState(false);
  
    // useEffect(() => {

    //     let groups = [];

    //     for (let j=0; j<user.groups.length; j++){
    //         let roles = user.groups[j].roles;
    //         let name = user.groups[j].name;

    //         if (name === 'incident') {
    //             for (let i=0; i< roles.length; i++) {
    //                 if (roles[i].name === 'review') {
    //                     setIsReviewRole(true);
    //                 }
    //                 else if (roles[i].name === 'investigation') {
    //                     setIsInvestigationRole(true);
    //                 }
    //                 else {
    //                     setIsGenergalRole(true);
    //                 }
    //             }
    //         }
    //     };
        
    // },[user]);

    const incidentHasEvidence = (e) =>{
          
        e.target.value ==='yes' ? setHasEvidence('yes') : setHasEvidence('no');
    }
    
    const incidentHasInjury =  (e) => {
        e.target.value === 'yes' ? setHasInjury('yes') : setHasInjury('no');
    }

    const incidentHasWitness =  (e) => {
        console.log ("output:", e.target.value );

        e.target.value === 'yes' ? setHasWitness('yes') : setHasWitness('no');
    }

    const SaveReport = (e) => {
        setSaved(true);

        setDisableSaveButton(true);
        setDisableSubmitButton(false);
    }

    const SubmitReport = (e) => {
        setSubmitted(true);

        setDisableSaveButton(true);
        setDisableSubmitButton(true);

    }

    const handleTabSelect = (e, newValue) => {
        console.log (e);
        setValue(newValue);
    }

    const incidentCategoryChange = (e) => {
        console.log ("category", e.currentTarget.value);
        setSelectedCategory(e.currentTarget.value);
    }
    
    const incidentRecChange = (e,formItem) => {
        console.log ("item:",formItem);

        switch (formItem) {
            case 'reportDate':
                rec.incidentRptDate = e.target.value;
                break;
            default:
            
        }
          
    }


    useEffect(() => {
        if (rec.hasInjury === 'Y') {
            setHasInjury('yes');
        }
        else {
            setHasInjury('no');
        }

        if (rec.hasWitness === 'Y') {
            setHasWitness('yes');
        }
        else {
            setHasWitness('no');
        }

        if(rec.hasEvidence === 'Y') {
            setHasEvidence('yes');
        }
        else setHasEvidence('no');

        switch ( rec.category) {
            case 'Lost Time':
                setSelectedCategory("Lost Time");
                break;
            case 'Vehicle Collision':
                setSelectedCategory("Vehicle Collision");
                break;
            case 'Body Injury':
                setSelectedCategory("Body Injury");
                break;
            default:
                setSelectedCategory("");
                
        }

        setReadContact(true);
        if (currentRole === 'general') {
            switch (rec.status) {
                case 'not submitted':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(true);
                    //setReadContact(true);
                    setReadElem(false);
                    break;
                case 'investigation':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(true);
                  
                    setReadElem(true);
                    break;
                case 'investigation completed':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(false);
                    setReadElem(true);
                    setReadElemInvestigate(true);
                    break;
                case 'review':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(false);
                    setReadElem(true);
                    break;
                case 'closed':
                    setReviewTabDisabled(false);
                    setInvestigateTabDisabled(false);
                    setReadElem(true);
                    setReadElemInvestigate(true);
                    setReadElemReview(true);
                    break;
                default:
                    break;
            }
        }

        if (currentRole === 'investigation') {
            rec.investigatedBy.employeeID = user.employeeID;
            rec.investigatedBy.firstName = user.firstName;
            rec.investigatedBy.lastName = user.lastName;
            rec.investigatedBy.email = user.email;
            rec.investigatedBy.phone = user.phone;

            switch (rec.status) {
                case 'investigation':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(false);
                    setReadElemInvestigate(false);
                    setReadElem(true);
                    break;
                case 'investigation completed':
                    setReviewTabDisabled(true);
                    setInvestigateTabDisabled(false);
                    setReadElem(true);
                    setReadElemInvestigate(true);
                    break;
                default:
                    break;
            }
        }

        if (currentRole === 'review') {
            rec.revieweddBy.employeeID = user.employeeID;
            rec.revieweddBy.firstName = user.firstName;
            rec.revieweddBy.lastName = user.lastName;
            rec.revieweddBy.email = user.email;
            rec.revieweddBy.phone = user.phone;

            switch (rec.status) {
                // case 'investigation completed':
                //     setReviewTabDisabled(false);
                //     setInvestigateTabDisabled(false);
                //     setReadElem(true);
                //     setReadElemInvestigate(true);
                //     break;
                case 'review':
                    setReviewTabDisabled(false);
                    setInvestigateTabDisabled(false);
                    setReadElemInvestigate(true);
                    setReadElem(true);
                    setReadElemReview(false);
                    break;
                case 'closed':
                    setReviewTabDisabled(false);
                    setInvestigateTabDisabled(false);
                    setReadElem(true);
                    setReadElemInvestigate(true);
                    setReadElemReview(true);
                    break;
                default:
                    break;
            }
        }

    },[hasInjury, hasEvidence, hasWitness, selectedCategory, investigateTabDisabled, reviewTabDisabled])

    useEffect(()=> {
        const map = new Map ({
        basemap: "satellite"

      });

      const mapView = new MapView({
      container: mapDiv.current,
        map: map,
        center: [-113.744, 52.477],
        zoom: 16,
        constraints: {
          maxZoom: 17,
          minZoom: 8,
        },
      
      });

      const searchWidget = new Search ({
        view: mapView,
        allPlaceholder: "",
        includeDefaultSources: true,
        sources: [
        //   {
        //     layer: fLayer,
        //     searchFields: ["NAME","STATE","COUNTY"],
        //     displayField: "NAME",
        //     exactMatch: false,
        //     outFields: ["*"],
        //     name: "POPULATION",
        //     placeholder: "e.g., Arizona"
        //   },
        //   {
        //     name: "ArcGIS World Geocoding Service",
        //     placeholder: "example: 61024 Dwight McLellan Trail",
        //     apiKey: "YOUR_API_KEY",
        //     singleLineFieldName: "SingleLine",
        //     url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer"
        //   }
        ]
      });
      mapView.ui.add(searchWidget, "top-right");

      mapView.when (() =>{
       setMapView (mapView);
       setMap (map);
    });
   }, [value]);


    return (
        <>
         <div style = {{width: "100%",  paddingLeft: "20px",paddingRight: "20px"}}>
            <div>
                <Row>
                    <Col>
                         <h3> Incident Report </h3>
                    </Col>
                    <Col style = {{display: "flex", justifyContent:"right"}}>
                        <label>Incident Number: {rec.incidentNum}</label>
                    </Col>
                </Row>
                
            </div>
            <hr/>
        </div>
        <Box mb={{ width: '100%', typography: 'body1' }}>
         <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabSelect} aria-label="Incident Investigation">
                    <Tab label="Report Details"  value="1" />
                    {/* <Tab label="Incident Investigation"  disabled={investigateTabDisabled} value="2" />
                    <Tab label="Incident Review"  disabled={reviewTabDisabled} value="3" /> */}
                    { !investigateTabDisabled && <Tab label="Incident Investigation"  value="2" /> }
                    { !reviewTabDisabled && <Tab label="Incident Review"   value="3" /> }
                </TabList>
            </Box>
            <TabPanel value="1">
                <Form style={{width: "100%", height:"600px", overflowY:"auto", overflowX:"hidden"}}>
                    <Row className="mb-3">
                        <Form.Group as = {Col} className = "mb-3"  controlId="userEmployeeId">
                            <Form.Label>Employee ID:</Form.Label>
                            <Form.Control type="text" required disabled = {readContact} pleaceholder = "Enter First Name" defaultValue = {rec.reportedBy.employeeID} />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3"  controlId="userFirstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control type="text" required disabled = {readContact} pleaceholder = "Enter First Name" defaultValue = {rec.reportedBy.firstName} />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userLastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control type="text" disabled = {readContact} pleaceholder = "Enter Last Name" defaultValue = {rec.reportedBy.lastName}/>
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userEmail">
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control type="email" disabled = {readContact} pleaceholder = "Enter email" defaultValue = {rec.reportedBy.email}/>
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userPhone">
                            <Form.Label>Phone #:</Form.Label>
                            <Form.Control type="text" disabled = {readContact} pleaceholder = "(403) 000-0000" defaultValue = {rec.reportedBy.phone}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <div id="mapDiv" style = {{width: "100%", height: "350px"}}  ref = {mapDiv}> 
                                <>
                                </>
                            </div>
                        </Col>
                        <Col>
                            <Row>
                                <Form.Group as = {Col} className="mb-3" controlId="address">
                                    <Form.Label>Incident Location:</Form.Label>
                                    <Form.Control type = "text"  disabled = {readElem} placeholder="Enter the incident location" defaultValue = {rec.location} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as = {Col} className="mb-3" controlId="coordinates">
                                    <Form.Label>Incident Location Coordinates:</Form.Label>
                                    <Form.Control type = "text"  disabled = {readElem}  placeholder="Enter the incident location" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as = {Col} className="mb-3" controlId="occurredDate">
                                    <Form.Label>Incident Occurred Date:</Form.Label>
                                    <Form.Control type = "date"  disabled = {readElem} placeholder="Enter the incident Date" defaultValue = {rec.incidentDate}/>
                                    
                                </Form.Group>
                                <Form.Group as = {Col} className="mb-3" controlId="occurredTime">
                                    <Form.Label>Incident Occurred Time:</Form.Label>
                                    <Form.Control type = "time"  disabled = {readElem} placeholder="Enter the incident location" defaultValue = {rec.incidentTime} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as = {Col} className="mb-3" controlId="reportedDate">
                                    <Form.Label>Incident Reported Date:</Form.Label>
                                    <Form.Control type = "date"  disabled = {readElem} placeholder="Enter the incident Date" defaultValue = {rec.incidentRptDate} onChange={event => incidentRecChange(event,'reportDate')}/>
                                    
                                </Form.Group>
                                <Form.Group as = {Col} className="mb-3" controlId="reportedTime">
                                    <Form.Label>Incident Reported Time:</Form.Label>
                                    <Form.Control type = "time"  disabled = {readElem} placeholder="Enter the incident location" defaultValue = {rec.incidentRptTime} onChange={event => incidentRecChange(event,'reportDate')}/>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="incidentCategory">
                            <Form.Label>Incident Category:</Form.Label>
                            <Form.Select  disabled = {readElem} defaultValue = {selectedCategory} area-label = "Default select example" onChange = {incidentCategoryChange}>
                                <option value ="">Select an incident type</option>
                                <option value ="Lost Time">Lost Time</option>
                                <option value ="Vehicle Collision">Vehicle Collision</option>
                                <option value ="Body Injury">Body Injury</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlID='incidentDescription'>
                            <Form.Label>Incident Description:</Form.Label>
                            <Form.Control as="textarea"  disabled = {readElem} spellCheck={true} rows={4} defaultValue = {rec.description}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                            <Form.Label column sm={3}>Were there any witnesses to the incident?</Form.Label>
                            <Col sm={1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"Yes"} name= "witnessGroup" id="incidentWitnessYes" value = "yes" checked = {hasWitness === 'yes'} onChange={incidentHasWitness}/> 
                            </Col>
                            <Col sm={1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"No"}  name= "witnessGroup" id="incidentWitnessNo"  value = "no" checked = {hasWitness === 'no'} onChange={incidentHasWitness}/>
                            </Col>
                        </Form.Group>
                        {hasWitness === 'yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                            <Form.Label>witness Contact Info:</Form.Label>
                            <Form.Control as="textarea"  disabled = {readElem} spellCheck={true} rows={2} value = {rec.witnessInfo}/>
                        </Form.Group> }
                    </Row>
                    <Row>
                        <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                            <Form.Label column sm = {2} >Were there any Injuries?</Form.Label>
                        
                            <Col sm={1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"Yes"} name= "witnessInjuryGroup" id="incidentInjuryYes" value = "yes" checked = {hasInjury === 'yes'} onChange={incidentHasInjury}/> 
                            </Col>
                            <Col sm={1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"No"}  name= "witnessInjuryGroup" id="incidentInjuryNo" value = "no" checked = {hasInjury === 'no'} onChange={incidentHasInjury}/>
                            </Col>
                        </Form.Group>
                        {hasInjury==='yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                            <Form.Label>Injury Description:</Form.Label>
                            <Form.Control as="textarea"  disabled = {readElem} spellCheck={true} rows={2} defaultValue = {rec.injuryInfo} />
                        </Form.Group> }
                    </Row>
                    <Row>
                        <Form.Group as ={Row} className="mb-3" controlID='incidentWitness'>
                        
                            <Form.Label column sm={4}> Do you have any evidence(photos, videos, etc.) of the incident?</Form.Label>
                            
                            <Col sm={1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"Yes"} name= "witnessEvidenceGroup" id="incidentEvidenceYes" value = "yes" checked = {hasEvidence === 'yes'} onChange={incidentHasEvidence}/> 
                            </Col>
                            <Col sm = {1}>
                                <Form.Check required type="radio"  disabled = {readElem} label={"No"}  name= "witnessEvidenceGroup" id="incidentEvidenceNo" value = "no" checked = {hasEvidence === 'no'} onChange={incidentHasEvidence} />
                            </Col>
                        </Form.Group>
                        { hasEvidence==='yes' && <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                            <Form.Label>Please upload the evidence here:</Form.Label>
                            <FileUploader name="incidentFiles" types={["JPG",'png',"GIF"]}/>
                        </Form.Group> }
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" controlID='incidentWitnessInfo'>
                            <Form.Label>The following employee(s) will be notified:</Form.Label>
                            <Form.Control as="textarea"  disabled = {readElem} spellCheck={true} rows={1}  defaultValue = {rec.notify} />
                        </Form.Group> 
                    </Row>
                </Form>
                <Row>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="save" disabled={disableSaveButton} onClick={SaveReport}>
                            Save Report
                        </Button>
                    </Col>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="submit" disabled={disableSubmitButton} onClick={SubmitReport}>
                            Submit Report
                        </Button> 
                    </Col>
                </Row>
                </TabPanel>
            <TabPanel value="2">
                <Form style={{width: "100%", height:"auto", overflowY:"auto", overflowX:"hidden"}}>
                    <Row className="mb-3">
                        <Form.Group as = {Col} className = "mb-3"  controlId="userEmployeeId">
                            <Form.Label>Employee ID:</Form.Label>
                            <Form.Control type="text" required disabled = {readContact} pleaceholder = "Enter Employee ID" defaultValue = {rec.investigatedBy.employeeID} />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3"  controlId="userFirstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control type="text" required pleaceholder = "Enter First Name" disabled = {readContact}  defaultValue = {rec.investigatedBy.firstName}/>
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userLastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control type="text" pleaceholder = "Enter Last Name"  disabled = {readContact} defaultValue = {rec.investigatedBy.lastName}/>
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userEmail" >
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control type="email" pleaceholder = "Enter email"  disabled = {readContact} defaultValue = {rec.investigatedBy.email}/>
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userPhone">
                            <Form.Label>Phone #:</Form.Label>
                            <Form.Control type="text" pleaceholder = "(403) 000-0000"  disabled = {readContact} defaultValue = {rec.investigatedBy.phone}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as = {Col} className="mb-3" controlId="occurredDate">
                            <Form.Label>Investigation Date:</Form.Label>
                            <Form.Control type = "date" style = {{width: 250} } placeholder="Enter the incident Date"  disabled = {readElemInvestigate} defaultValue = {rec.investigateDate} />
                        </Form.Group>
                        
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlID='incidentDescription'>
                            <Form.Label>Investigation Comments:</Form.Label>
                            <Form.Control as="textarea" spellCheck={true} rows={4}  disabled = {readElemInvestigate} defaultValue = {rec.investigateComment}/>
                        </Form.Group>
                    </Row>
                </Form>
                <Row>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="save" disabled={disableSaveButton} onClick={SaveReport}>
                            Save Report
                        </Button>
                    </Col>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="submit" disabled={disableSubmitButton} onClick={SubmitReport}>
                            Submit Report
                        </Button> 
                    </Col>
                </Row>
            </TabPanel>
            <TabPanel value="3">
            <Form style={{width: "100%", height:"auto", overflowY:"auto", overflowX:"hidden"}}>
                    <Row className="mb-3">
                        <Form.Group as = {Col} className = "mb-3"  controlId="userEmployeeId">
                            <Form.Label>Employee ID:</Form.Label>
                            <Form.Control type="text" required disabled = {readContact} pleaceholder = "Enter Employee ID" defaultValue = {rec.revieweddBy.employeeID} />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3"  controlId="userFirstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control type="text" required   disabled = {readContact} defaultValue = {rec.revieweddBy.firstName}  pleaceholder = "Enter First Name" />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userLastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control type="text"   disabled = {readContact} defaultValue = {rec.revieweddBy.lastName}  pleaceholder = "Enter Last Name" />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userEmail">
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control type="email"   disabled = {readContact} defaultValue = {rec.revieweddBy.email}  pleaceholder = "Enter email" />
                        </Form.Group>
                        <Form.Group as = {Col} className = "mb-3" controlId="userPhone">
                            <Form.Label>Phone #:</Form.Label>
                            <Form.Control type="text"   disabled = {readContact}  defaultValue = {rec.revieweddBy.phone}  pleaceholder = "(403) 000-0000" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as = {Col} className="mb-3" controlId="occurredDate">
                            <Form.Label>Review Date:</Form.Label>
                            <Form.Control type = "date" style = {{width: 250} }   disabled = {readElemReview}  defaultValue = {rec.reviewedDate} placeholder="Enter the incident Date" />
                        </Form.Group>
                        
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlID='incidentDescription'>
                            <Form.Label>Review Comments:</Form.Label>
                            <Form.Control as="textarea" spellCheck={true} rows={4}  defaultValue = {rec.reviewComment}  disabled = {readElemReview}  />
                        </Form.Group>
                    </Row>
                </Form>
                <Row>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="save" disabled={disableSaveButton} onClick={SaveReport}>
                            Save Report
                        </Button>
                    </Col>
                    <Col sm = {1}>
                        <Button  variant = "primary" type="submit" disabled={disableSubmitButton} onClick={SubmitReport}>
                            Submit Report
                        </Button> 
                    </Col>
                </Row>
            </TabPanel>
        </TabContext> 
        </Box>
       
        
        </>
    );
}

export default InvestigateIncident;