
import React, {useState,useEffect, useRef} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {DataGrid} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BarChart } from "@mui/x-charts/BarChart";
import 'bootstrap/dist/css/bootstrap.min.css';
import {PieChart} from '@mui/x-charts/PieChart';

const MyDashboard = () => {


    return (
        <>
         <div style = {{width:"100%", height: "100%"}}>
            <Row style={{textAlignLast: "center", height: "45px", fontSize:"20pt", marginBottom:"20px"}}>
                <span>My Dashboard</span>
            </Row>
            <Row>
                <Col style={{display: "flex", flexDirection: "column", alignItems:"center"}}>
                    <BarChart
                        series={[
                            { data: [3, 5, 10] },
                            // { data: [5] },
                            // { data: [15] },

                        ]}
                        height={200}
                        width={300}
                        xAxis={[{ data: ['Lost Time', 'Body Injury', 'Vehcile Collision'], scaleType: 'band' }]}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                    <p style = {{alignItems: "center"}}>Incident Report Number per Category</p>
                </Col>

                <Col style={{display: "flex", flexDirection: "column", alignItems:"center"}}>
                    <BarChart
                        series={[
                            { data: [13, 5, 3,2,3] },
                            // { data: [5] },
                            // { data: [15] },

                        ]}
                        height={200}
                        width={300}
                        xAxis={[{ data: ['Submitted', 'In Invest', 'Invest Complete', 'In Review', "Closed"], scaleType: 'band' }]}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    />
                    <p style = {{alignItems: "center"}}>Incident Report Number per Status</p>
                </Col>
            </Row>
            <Row>
                <Col style={{display: "flex", flexDirection: "column", alignItems:"center"}}>
                    <PieChart
                        series={[
                            {
                              data: [
                                { id: 0, value: 5, label: 'In Investigation' },
                                { id: 1, value: 3, label: 'Investigation Completed' },
                                { id: 2, value: 2, label: 'In Review' },
                                { id: 3, value: 3, label: 'Closed' },
                              ],
                              highlightScope: { faded: 'global', highlighted: 'item' },
                              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                          ]}
                          width={600}
                          height={200}
                          margin={{ top: 30 }}
                        />
                 
                    <p style = {{alignItems: "center"}}>Incident Report Number per Status</p>
                </Col>       
            </Row>

                {/* <Row>
                    <Col>

                    </Col>
                </Row>
                <Row>

                </Row> */}

             </div>



        </>
    );
}

export default MyDashboard;